"use client"

import { useState } from "react"
import { motion } from "framer-motion"
import { Plus, Wallet, Edit2, Trash2, Check } from "lucide-react"
/* import { Button } from "@/components/ui/button"
import { textarea } from "@/components/ui/textarea"
import { input } from "@/components/ui/input"
import { label } from "@/components/ui/label" */
// import { Card, Card.Body, h6, Card.Footer, Card.Header, h5 } from "@/components/ui/card"
import { Card, Button } from 'react-bootstrap'

export default function CatalogPage(props) {
    const { firstName, lastName, email, account } = props.user;
    const [selectedProduct, setSelectedProduct] = useState("video")
    const [selectedOptions, setSelectedOptions] = useState({})
    const [description, setDescription] = useState("")
    const [thumbnail, setThumbnail] = useState(null)
    const [preview, setPreview] = useState(null)

    const products = [
        { id: "video", label: "VIDEO", color: "from-blue-500 to-cyan-500" },
        { id: "audio", label: "AUDIO", color: "from-purple-500 to-pink-500" },
        { id: "nft", label: "NFT", color: "from-green-500 to-emerald-500" },
        { id: "tickets", label: "TICKETS", color: "from-yellow-500 to-orange-500" },
        { id: "message", label: "MESSAGE", color: "from-red-500 to-rose-500" },
        { id: "subs", label: "SUBS", color: "from-indigo-500 to-violet-500" },
    ]

    const durations = [
        { mins: "5", price: "50" },
        { mins: "10", price: "75" },
        { mins: "15", price: "85" },
        { mins: "30", price: "100" },
        { mins: "60", price: "150" },
        { mins: "120", price: "200" },
    ]

    const messageTypes = [
        { id: "demo", label: "Demo Feedback", price: "50" },
        { id: "career", label: "Career Advice", price: "100" },
        { id: "production", label: "Production Advice", price: "75" },
    ]

    const handleOptionSelect = (option, isSelected) => {
        setSelectedOptions((prev) => ({
            ...prev,
            [selectedProduct]: {
                ...prev[selectedProduct],
                [option.id || option.mins]: isSelected ? option : undefined,
            },
        }))
    }

    const handlePriceChange = (option, newPrice) => {
        setSelectedOptions((prev) => ({
            ...prev,
            [selectedProduct]: {
                ...prev[selectedProduct],
                [option.id || option.mins]: { ...option, price: newPrice },
            },
        }))
    }

    const isOptionSelected = (option) => {
        return !!selectedOptions[selectedProduct]?.[option.id || option.mins]
    }

    const renderStep2Content = () => {
        switch (selectedProduct) {
            case "video":
            case "audio":
                return (
                    <Card className="bg-gray-900/50 backdrop-blur-sm border-gray-800" style={{background: 'black', border: '1px solid gray'}}>
                        <Card.Header>
                            <h5 className="text-cyan-400">Time Duration</h5>
                            <h6 className="text-gray-300">Select the duration for your content</h6>
                        </Card.Header>
                        <Card.Body>
                            <div className="grid grid-cols-2 gap-4">
                                {durations.map((duration) => (
                                    <div key={duration.mins} className="relative">
                                        <button
                                            onClick={() => handleOptionSelect(duration, !isOptionSelected(duration))}
                                            className={`w-full p-3 rounded-lg text-left transition-colors ${isOptionSelected(duration)
                                                    ? "bg-cyan-500/20 border-2 border-cyan-500"
                                                    : "bg-gray-800/50 border border-gray-700 hover:bg-gray-800"
                                                }`}
                                        >
                                            <div className="font-medium text-gray-200">{duration.mins} mins</div>
                                            <input
                                                type="text"
                                                value={selectedOptions[selectedProduct]?.[duration.mins]?.price || duration.price}
                                                onChange={(e) => handlePriceChange(duration, e.target.value)}
                                                className="mt-1 w-20 bg-gray-700 border-gray-600 text-gray-200"
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                        </button>
                                        {isOptionSelected(duration) && <Check className="absolute top-2 right-2 text-cyan-500" />}
                                    </div>
                                ))}
                            </div>
                        </Card.Body>
                    </Card>
                )

            case "tickets":
                return (
                    <Card className="bg-gray-900/50 backdrop-blur-sm border-gray-800" style={{background: 'black', border: '1px solid gray'}}>
                        <Card.Header>
                            <h5 className="text-yellow-400">Ticket Configuration</h5>
                            <h6 className="text-gray-300">Set up your ticket tiers and quantities</h6>
                        </Card.Header>
                        <Card.Body className="space-y-6">
                            <div className="space-y-4">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-lg font-medium text-gray-200">Ticket Tiers</h3>
                                    <Button variant="outline-info" className="border-yellow-500 hover:bg-yellow-500/20 text-yellow-400">
                                        <Plus className="mr-2 h-4 w-4" /> Add Tier
                                    </Button>
                                </div>
                                <div className="space-y-4">
                                    <div className="grid gap-4 p-4 rounded-lg bg-gray-800/50 border border-gray-700">
                                        <div className="grid grid-cols-3 gap-4">
                                            <input placeholder="Tier Name" className="bg-gray-700 border-gray-600 text-gray-200" />
                                            <input type="text" placeholder="Price" className="bg-gray-700 border-gray-600 text-gray-200" />
                                            <input type="text" placeholder="Quantity" className="bg-gray-700 border-gray-600 text-gray-200" />
                                        </div>
                                        <input placeholder="Description" className="bg-gray-700 border-gray-600 text-gray-200" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center p-4 rounded-lg bg-gray-800/50 border border-gray-700">
                                <span className="text-gray-300">Total Tickets</span>
                                <span className="text-yellow-400 font-bold">0</span>
                            </div>
                        </Card.Body>
                    </Card>
                )

            case "nft":
                return (
                    <Card className="bg-gray-900/50 backdrop-blur-sm border-gray-800" style={{background: 'black', border: '1px solid gray'}}>
                        <Card.Header>
                            <h5 className="text-green-400">NFT Marketplace Connection</h5>
                            <h6 className="text-gray-300">Connect your wallet and choose a marketplace</h6>
                        </Card.Header>
                        <Card.Body className="space-y-6">
                            <Button className="w-full bg-gradient-to-r from-green-500 to-emerald-500 hover:opacity-90">
                                <Wallet className="mr-2 h-4 w-4" /> Connect Wallet
                            </Button>
                            <div className="grid grid-cols-2 gap-4">
                                {["OpenSea", "Rarible", "Foundation", "SuperRare"].map((marketplace) => (
                                    <button
                                        key={marketplace}
                                        onClick={() => handleOptionSelect({ id: marketplace }, !isOptionSelected({ id: marketplace }))}
                                        className={`relative p-4 rounded-lg text-left transition-colors ${isOptionSelected({ id: marketplace })
                                                ? "bg-green-500/20 border-2 border-green-500"
                                                : "bg-gray-800/50 border border-gray-700 hover:bg-green-500/10 hover:border-green-500"
                                            }`}
                                    >
                                        <span className="text-gray-200">{marketplace}</span>
                                        {isOptionSelected({ id: marketplace }) && (
                                            <Check className="absolute top-2 right-2 text-green-500" />
                                        )}
                                    </button>
                                ))}
                            </div>
                        </Card.Body>
                    </Card>
                )

            case "message":
                return (
                    <Card className="bg-gray-900/50 backdrop-blur-sm border-gray-800" style={{background: 'black', border: '1px solid gray'}}>
                        <Card.Header>
                            <h5 className="text-rose-400">Message Type</h5>
                            <h6 className="text-gray-300">Select the type of consultation</h6>
                        </Card.Header>
                        <Card.Body>
                            <div className="grid gap-4">
                                {messageTypes.map((type) => (
                                    <div key={type.id} className="relative">
                                        <button
                                            onClick={() => handleOptionSelect(type, !isOptionSelected(type))}
                                            className={`w-full p-4 rounded-lg text-left transition-colors ${isOptionSelected(type)
                                                    ? "bg-rose-500/20 border-2 border-rose-500"
                                                    : "bg-gray-800/50 border border-gray-700 hover:bg-rose-500/10 hover:border-rose-500"
                                                }`}
                                        >
                                            <div className="flex justify-between items-center">
                                                <span className="font-medium text-gray-200">{type.label}</span>
                                                <input
                                                    type="text"
                                                    value={selectedOptions[selectedProduct]?.[type.id]?.price || type.price}
                                                    onChange={(e) => handlePriceChange(type, e.target.value)}
                                                    className="w-20 bg-gray-700 border-gray-600 text-gray-200"
                                                    onClick={(e) => e.stopPropagation()}
                                                />
                                            </div>
                                        </button>
                                        {isOptionSelected(type) && <Check className="absolute top-2 right-2 text-rose-500" />}
                                    </div>
                                ))}
                            </div>
                        </Card.Body>
                    </Card>
                )

            case "subs":
                return (
                    <Card className="bg-gray-900/50 backdrop-blur-sm border-gray-800" style={{background: 'black', border: '1px solid gray'}}>
                        <Card.Header>
                            <h5 className="text-violet-400">Subscription Tiers</h5>
                            <h6 className="text-gray-300">
                                Configure your subscription tiers and benefits
                            </h6>
                        </Card.Header>
                        <Card.Body className="space-y-6">
                            <div className="space-y-4">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-lg font-medium text-gray-200">Membership Tiers</h3>
                                    <Button variant="outline-info" className="border-violet-500 hover:bg-violet-500/20 text-violet-400">
                                        <Plus className="mr-2 h-4 w-4" /> Add Tier
                                    </Button>
                                </div>
                                {[
                                    { name: "Bronze", price: "5", benefits: ["Exclusive posts", "Behind the scenes"] },
                                    { name: "Silver", price: "15", benefits: ["Bronze benefits", "Priority access", "Discord role"] },
                                    { name: "Gold", price: "30", benefits: ["Silver benefits", "Monthly meetup", "Custom badge"] },
                                ].map((tier, index) => (
                                    <div key={index} className="p-4 rounded-lg bg-gray-800/50 border border-gray-700">
                                        <div className="grid gap-4">
                                            <div className="flex justify-between items-center">
                                                <input value={tier.name} className="max-w-[200px] bg-gray-700 border-gray-600 text-gray-200" />
                                                <input
                                                    type="text"
                                                    value={tier.price}
                                                    className="max-w-[100px] bg-gray-700 border-gray-600 text-gray-200"
                                                />
                                            </div>
                                            <div className="space-y-2">
                                                {tier.benefits.map((benefit, benefitIndex) => (
                                                    <div key={benefitIndex} className="flex items-center gap-2">
                                                        <input value={benefit} className="bg-gray-700 border-gray-600 text-gray-200" />
                                                    </div>
                                                ))}
                                                <Button
                                                    variant="outline-info"
                                                    size="sm"
                                                    className="w-full border-violet-500/50 hover:bg-violet-500/20 text-violet-400"
                                                >
                                                    <Plus className="mr-2 h-4 w-4" /> Add Benefit
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Card.Body>
                    </Card>
                )

            default:
                return null
        }
    }

    const renderSummary = () => {
        return (
            <Card className="bg-gray-900/50 backdrop-blur-sm border-gray-800" style={{background: 'black', border: '1px solid gray'}}>
                <Card.Header>
                    <h5 className="text-cyan-400">Catalog Summary</h5>
                    <h6 className="text-gray-300">Review and edit your selections</h6>
                </Card.Header>
                <Card.Body className="space-y-6">
                    {Object.entries(selectedOptions).map(([product, options]) => (
                        <div key={product} className="space-y-2">
                            <h3 className="text-lg font-medium text-gray-200">{product.toUpperCase()}</h3>
                            {Object.entries(options).map(([key, option]) => (
                                <div key={key} className="flex justify-between items-center p-2 rounded bg-gray-800/50">
                                    <span className="text-gray-300">{option.label || `${option.mins} mins`}</span>
                                    <div className="flex items-center space-x-2">
                                        <span className="text-cyan-400">${option.price}</span>
                                        <Button size="icon" variant="ghost" className="h-8 w-8 text-gray-400 hover:text-cyan-400">
                                            <Edit2 className="h-4 w-4" />
                                        </Button>
                                        <Button size="icon" variant="ghost" className="h-8 w-8 text-gray-400 hover:text-rose-400">
                                            <Trash2 className="h-4 w-4" />
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </Card.Body>
            </Card>
        )
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-950 via-slate-950 to-gray-950 text-white p-6">
            <div className="max-w-6xl mx-auto space-y-12">
                <div className="flex justify-between items-center">
                    <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-cyan-400 to-blue-500">
                        YOUR CATALOG
                    </h1>
                    <Button variant="outline-info" className="border-cyan-500 hover:bg-cyan-500/20 text-cyan-400">
                        CREATE / EDIT CATALOG
                    </Button>
                </div>

                <section className="space-y-4">
                    <h2 className="text-xl font-semibold text-gray-300">1. CHOOSE YOUR PRODUCTS OR SERVICES</h2>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                        {products.map((product) => (
                            <motion.button
                                key={product.id}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => setSelectedProduct(product.id)}
                                className={`relative p-4 rounded-lg bg-gray-900/50 backdrop-blur-sm border 
                  ${selectedProduct === product.id
                                        ? `border-${product.color.split("-")[2]} shadow-lg shadow-${product.color.split("-")[2]}/20`
                                        : "border-gray-800 hover:border-gray-700"
                                    }`}
                            >
                                <div className={`absolute inset-0 opacity-10 rounded-lg bg-gradient-to-br ${product.color}`} />
                                <span className="relative text-sm font-medium">{product.label}</span>
                            </motion.button>
                        ))}
                    </div>
                </section>

                <section className="space-y-4">
                    <h2 className="text-xl font-semibold text-gray-300">2. SELECT OPTIONS</h2>
                    {renderStep2Content()}
                </section>

                <section className="space-y-4">
                    <h2 className="text-xl font-semibold text-gray-300">3. ADD DESCRIPTION AND PROMOTIONAL MATERIAL</h2>
                    <Card className="bg-gray-900/50 backdrop-blur-sm border-gray-800" style={{background: 'black', border: '1px solid gray'}}>
                        <Card.Header>
                            <h5 className="text-cyan-400">Content Details</h5>
                            <h6 className="text-gray-300">
                                Describe your content and add promotional materials
                            </h6>
                        </Card.Header>
                        <Card.Body className="space-y-4">
                            <textarea
                                placeholder="Add your description here..."
                                className="min-h-[150px] bg-gray-900/50 border-gray-800 focus-visible:ring-cyan-500 text-gray-200"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <div className="grid sm:grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="thumbnail-upload" className="block mb-2 text-sm font-medium text-gray-300">
                                        Thumbnail
                                    </label>
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-700 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg
                                                className="mx-auto h-12 w-12 text-gray-400"
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 48 48"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <div className="flex text-sm text-gray-400">
                                                <label
                                                    htmlFor="thumbnail-upload"
                                                    className="relative cursor-pointer bg-gray-900 rounded-md font-medium text-cyan-400 hover:text-cyan-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cyan-500"
                                                >
                                                    <span>Upload a file</span>
                                                    <input
                                                        id="thumbnail-upload"
                                                        name="thumbnail-upload"
                                                        type="file"
                                                        className="sr-only"
                                                        onChange={(e) => setThumbnail(e.target.files[0])}
                                                    />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="preview-upload" className="block mb-2 text-sm font-medium text-gray-300">
                                        Preview
                                    </label>
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-700 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg
                                                className="mx-auto h-12 w-12 text-gray-400"
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 48 48"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <div className="flex text-sm text-gray-400">
                                                <label
                                                    htmlFor="preview-upload"
                                                    className="relative cursor-pointer bg-gray-900 rounded-md font-medium text-cyan-400 hover:text-cyan-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cyan-500"
                                                >
                                                    <span>Upload a file</span>
                                                    <input
                                                        id="preview-upload"
                                                        name="preview-upload"
                                                        type="file"
                                                        className="sr-only"
                                                        onChange={(e) => setPreview(e.target.files[0])}
                                                    />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            <Button className="w-full bg-gradient-to-r from-cyan-500 to-blue-500 hover:opacity-90">
                                <Plus className="mr-2 h-4 w-4" /> Add to Catalog
                            </Button>
                        </Card.Footer>
                    </Card>
                </section>

                <section className="space-y-4">
                    <h2 className="text-xl font-semibold text-gray-300">4. REVIEW AND PUBLISH</h2>
                    {renderSummary()}
                    <Button className="w-full bg-gradient-to-r from-green-500 to-emerald-500 hover:opacity-90 text-lg py-6">
                        Publish Catalog
                    </Button>
                </section>
            </div>
        </div>
    )
}

