import '../../../style/ContactsTable.css'
import Card from 'react-bootstrap'

const ContactsPage = ({ contacts }) => {
  // const allcontacts = [
  //   {
  //     name: 'Chinmay Swain',
  //     email: 'cs@gmail.com',
  //     campaign: 'Artist Page',
  //     phone: '',
  //     location: '',
  //     lastMessage: '1/23/2023, 3:57 AM',
  //     messages: 1
  //   },
  //   {
  //     name: 'vig shetty',
  //     email: 'vigneshshetty27@gmail.com',
  //     campaign: 'RequestCity',
  //     phone: '',
  //     location: '',
  //     lastMessage: '6/4/2019, 12:13 AM',
  //     messages: 1
  //   }
  // ]
  const handleRowClick = (contact) => {
    console.log("Row clicked in Contact Table", contact)
    // Open another card or navigate to another page showing that contact's information
  };

  return (
    <div style={{ width: '100%'}}>
      <table id="contactTable" className="table">
        <thead className="row header">
          <tr>
            <th className="header-cell">NAME</th>
            <th className="header-cell">TYPE</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact, index) => (
            <tr 
              className="row button-row"
              key={index}
              onClick = {()=>handleRowClick(contact)}
            >
              <td className="cell">{ contact.name || '' }</td>
              <td className="cell">{ contact.accountType || '' }</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContactsPage;