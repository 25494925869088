
import { callApi } from '../../../utils/utils';
import { useState, useEffect } from "react"

// Sample data
const contacts = [
  {
    name: "Chinmay Swain",
    email: "cs@knkt.com",
    campaign: "Artist Page",
    phone: "",
    location: "",
    lastMessage: "1/23/2025, 3:57 AM",
    messages: 1,
  },
  {
    name: "vig shetty",
    email: "vig@knkt.com",
    campaign: "TikTok",
    phone: "",
    location: "",
    lastMessage: "1/4/2025, 12:13 AM",
    messages: 1,
  },
  {
    name: "philip brown",
    email: "phil@knkt.com",
    campaign: "Instagram",
    phone: "",
    location: "",
    lastMessage: "1/20/2025, 12:13 AM",
    messages: 1,
  },
  {
    name: "stan han",
    email: "stan@knkt.com",
    campaign: "Facebook",
    phone: "",
    location: "",
    lastMessage: "1/25/2025, 12:13 AM",
    messages: 1,
  },
  {
    name: "karan kumar",
    email: "karan@knkt.com",
    campaign: "Instagram",
    phone: "",
    location: "",
    lastMessage: "1/20/2025, 12:13 AM",
    messages: 1,
  },
]

const FansPage = () => {
  const [hoveredRow, setHoveredRow] = useState(null)
  const [apiData, setApiData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await callApi('/api/v1/users/follows?type=fan', 'get');
        console.log('API Response:', response);
        
        // Extract the data from the response
        const { data } = response;
        
        if (!data) {
          throw new Error('No data received from API');
        }

        if (data.status === 'successful' && data.follows) {
          const { followees, followers } = data.follows;
          console.log('Followees:', followees);
          console.log('Followers:', followers);
          
          // For fans page, we'll use followees (people being managed)
          setApiData(followees || []);
        } else {
          throw new Error(data.message || 'Failed to fetch data');
        }
        
      } catch (err) {
        console.error('API Error:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="text-center p-4">
          Loading...
        </div>
      )
    }

    if (error) {
      return (
        <div className="text-red-500 p-4">
          Error loading data: {error}
        </div>
      )
    }

    if (!apiData || apiData.length === 0) {
      return (
        <div className="text-center p-4">
          No fans data available
        </div>
      )
    }

    // If we have data, render the table
    return (
      <div className="w-full">
        {/* Table Header */}
        <div className="flex font-bold text-black bg-white">
          <div className="flex-1 p-3">NAME</div>
          <div className="flex-1 p-3">EMAIL</div>
          <div className="flex-1 p-3">ACCOUNT TYPE</div>
          <div className="flex-1 p-3 hidden md:block">PHONE</div>
          <div className="flex-1 p-3 hidden md:block">LOCATION</div>
          <div className="flex-1 p-3 hidden md:block">LAST MESSAGE</div>
          <div className="flex-1 p-3 hidden md:block">MESSAGES</div>
        </div>

        {/* Table Rows */}
        {apiData.map((contact, index) => (
          <div
            key={index}
            onMouseEnter={() => setHoveredRow(index)}
            onMouseLeave={() => setHoveredRow(null)}
            onClick={() => handleRowClick(contact)}
            className={`
              flex flex-row items-center border-b border-gray-600
              transition-all duration-200 cursor-pointer
              bg-black
              ${hoveredRow === index ? "ring-2 ring-[#8627df] ring-offset-2 ring-offset-black" : ""}
            `}
          >
            <div className="flex-1 p-3 break-words">{contact.name || `${contact.firstName} ${contact.lastName}` || '-'}</div>
            <div className="flex-1 p-3 break-words">{contact.email || '-'}</div>
            <div className="flex-1 p-3 break-words">{contact.accountType || '-'}</div>
            <div className="flex-1 p-3 break-words hidden md:block">{contact.phone || "-"}</div>
            <div className="flex-1 p-3 break-words hidden md:block">{contact.location || "-"}</div>
            <div className="flex-1 p-3 break-words hidden md:block">{contact.lastMessage || "-"}</div>
            <div className="flex-1 p-3 break-words hidden md:block">{contact.messages || "-"}</div>
          </div>
        ))}
      </div>
    )
  }

  // Handle row click
  const handleRowClick = (contact) => {
    console.log("Row clicked:", contact);
    // Add your row-click logic here
  }

  return (
    <div className="min-h-screen bg-black text-white px-4 py-4">
      <div className="max-w-6xl mx-auto">
        {/* NAVBAR / CONTROLS */}
        <nav className="flex justify-between items-center border-b border-gray-500 pb-2 mb-4">
          <div className="flex space-x-6 uppercase">
            <button className="hover:text-[#fbb655] transition-colors">
              Campaigns
            </button>
            <button className="hover:text-[#fbb655] transition-colors">
              My Contacts
            </button>
          </div>

          <button 
            className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-orange-500 transition-colors"
            onClick={() => console.log("Download CSV clicked")}
          >
            Download CSV
          </button>
        </nav>

        {/* Render content based on API state */}
        {renderContent()}
      </div>
    </div>
  )
}

export default FansPage