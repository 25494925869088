import React, { useState, useEffect } from 'react';
import '../../../style/settings.css'
import ContactsPage from './ContactsPage'
import { callApi } from '../../../utils/utils';

export default function Team({props}) {
    const [ followers, setFollowers ] = useState([]);
    const [ followees, setFollowees ] = useState([]);
    
    useEffect(() => {
        let mounted = true;
    

        const getFollowersFollowees = async () => {
            try{
                const resp = await callApi('/api/v1/users/follows?type=artist', 'get');
                if (resp.data && mounted) {
                    
                    const data = resp.data.follows;
                    const {followers, followees} = data;
                    const allFollowers = followers.map(fer => ({
                        name: fer.name,
                        accountType: fer.accountType
                    }));
                    
                    const allFollowees = followees.map(fer => ({
                        name: fer.name,
                        accountType: fer.accountType
                    }));
            
                    setFollowers(allFollowers);
                    setFollowees(allFollowees);
                }
            } catch (error) {
                console.error('Error fetching followers and followees', error)
            }

        };

        getFollowersFollowees()

        return () => {
            mounted = false
        };
    }, []);
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', color: 'white'}}>
            <div>
                <div>
                    Managing me:
                </div>
                <ContactsPage contacts={followees} />
            </div>
            <div>
                <div>
                    I'm managed by:
                </div>
                <ContactsPage contacts={followers} />
            </div>
        </div>
    )
};