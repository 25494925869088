import { useState } from "react"
import { Plus, BarChart, GripVertical } from "lucide-react"
import Facebook from 'react-icons/lib/fa/facebook';
import Instagram from 'react-icons/lib/fa/instagram';
import Youtube from 'react-icons/lib/fa/youtube';
import SoundCloud from 'react-icons/lib/fa/soundcloud';
import Twitter from 'react-icons/lib/fa/twitter';
import Spotify from 'react-icons/lib/fa/spotify';
import { FileTextOutlined } from '@ant-design/icons';
import { Button, Card, Avatar } from "antd";
import { ScrollArea } from '@radix-ui/react-scroll-area'
import { Separator } from "@radix-ui/react-separator";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import AddLinkModal from '../components/modals/AddLinkModal/AddLinkModal';
import AddTextModal from '../components/modals/AddTextModal/AddTextModal';
import AddContactFormModal from '../components/modals/AddContactFormModal/AddContactFormModal';
import AddEventModal from '../components/modals/AddEventModal/AddEventModal';
import "./templateBuilder.css";
import AnalyticsDialog from "../components/modals/AnalyticsModal/AnalyticsModal";

const TemplateBuilder = ({ user }) => {
  const [showAddLinkModal, setShowAddLinkModal] = useState(false)
  const [showAddTextModal, setShowAddTextModal] = useState(false)
  const [showAddContactFormModal, setShowAddContactFormModal] = useState(false)
  const [showAddEventModal, setShowAddEventModal] = useState(false)
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false)
  const [blocks, setBlocks] = useState([])

  const moduleTypes = ["link", "text", "contact", "event"]

  const addBlock = (type, content) => {
    setBlocks((prevBlocks) => [
      ...prevBlocks,
      {
        id: `${type}-${Date.now()}`,
        type,
        content,
      },
    ]);
  };

  const handleSaveLink = (link) => {
    addBlock("link", link);
    setShowAddLinkModal(false);
  };

  const handleSaveText = (text) => {
    addBlock("text", { content: text });
    setShowAddTextModal(false);
  };

  const handleSaveEvent = (event) => {
    addBlock("event", event);
    setShowAddEventModal(false);
  };

  const handleSaveContactForm = (contactForm) => {
    addBlock("contact", contactForm);
    setShowAddContactFormModal(false);
  };

  const openModal = (type) => {
    switch (type) {
      case "link":
        setShowAddLinkModal(true);
        break;
      case "text":
        setShowAddTextModal(true);
        break;
      case "contact":
        setShowAddContactFormModal(true);
        break;
      case "event":
        setShowAddEventModal(true);
        break;
    }
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(blocks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setBlocks(items);
  }

  return (
    <div className="page-wrapper">
      <div className="builder-header">
        <h1 className="title1">Link Builder</h1>
        <button className="analytics-button" onClick={() => setIsAnalyticsOpen(true)}>
          <BarChart size={20} />
          Analytics
        </button>
      </div>
      <div className="template-builder">
        <div className="builder-left">
          <AddLinkModal isOpen={showAddLinkModal} onSave={handleSaveLink} onClose={() => setShowAddLinkModal(false)} />

          <AddTextModal isOpen={showAddTextModal} onSave={handleSaveText} onClose={() => setShowAddTextModal(false)} />

          <AddContactFormModal
            isOpen={showAddContactFormModal}
            onSave={handleSaveContactForm}
            onClose={() => setShowAddContactFormModal(false)}
          />

          <AddEventModal
            isOpen={showAddEventModal}
            onSave={handleSaveEvent}
            onClose={() => setShowAddEventModal(false)}
          />
          <div className="builder-content">
            <div className="button-grid">
              {moduleTypes.map((type) => (
                <Button key={type} className={`add-block-button ${type}`} onClick={() => openModal(type)}>
                  <Plus className="plus-icon" />
                  New {type.charAt(0).toUpperCase() + type.slice(1)}
                </Button>
              ))}
            </div>
            <Separator />
            <ScrollArea className="blocks-list">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="blocks">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className="blocks-container">
                      {blocks.map((block, index) => (
                        <Draggable key={block.id} draggableId={block.id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className="draggable-block"
                            >
                              <div {...provided.dragHandleProps} className="drag-handle">
                                <GripVertical size={20} />
                              </div>
                              <PreviewBlock isDesktop block={block} />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </ScrollArea>
          </div>
        </div>
        <PreviewPane blocks={blocks} user={user} />
      </div>
      <AnalyticsDialog isOpen={isAnalyticsOpen} onClose={() => setIsAnalyticsOpen(false)} />
    </div>
  );
}

const PreviewPane = ({ blocks, user }) => {
  const showProfileIcons = () => {
    const icons = [];
    Object.entries(user.connectedProfiles).forEach(([key, value]) => {
      if (value.id) {
        switch (key) {
          case "facebook":
            icons.push(<Facebook className="facebook-icon" onClick={() => window.open(value.facebookUrl, "_blank")} />)
            break;
          case "instagram":
            icons.push(
              <Instagram className="instagram-icon" onClick={() => window.open(value.instagramUrl, "_blank")} />,
            );
            break;
          case "youtube":
            icons.push(<Youtube className="youtube-icon" onClick={() => window.open(value.youtubeUrl, "_blank")} />)
            break;
          case "twitter":
            icons.push(<Twitter className="twitter-icon" onClick={() => window.open(value.twitterUrl, "_blank")} />)
            break;
          case "spotify":
            icons.push(<Spotify className="spotify-icon" onClick={() => window.open(value.spotifyUrl, "_blank")} />)
            break;
          case "soundCloud":
            icons.push(<SoundCloud className="soundcloud-icon" onClick={() => window.open(value.soundCloudUrl, "_blank")} />)
            break;
          default:
            return null;
        }
      }
    })
    return icons;
  }

  return (
    <div className="preview-pane">
      <div className="preview-header">
        <h2 className="title2">Preview</h2>
      </div>
      <div className="phone-mockup">
        <div className="phone-notch"></div>

        <div className="phone-content">
          {/* Profile Section */}
          <div className="profile-section">
            {/* Avatar */}
            <div className="avatar">
              <img src={user.profileImage} alt="Avatar" className="avatar-image" />
            </div>
            {/* Username */}
            <h3 className="username">{user.firstName}</h3>
            <div className="social-icons-wrapper">{showProfileIcons()}</div>
          </div>

          {/* Preview Blocks */}
          {blocks.map((block) => (
            <PreviewBlock key={block.id} block={block} />
          ))}
        </div>
      </div>
    </div>
  );
};

const PreviewBlock = ({ isDesktop, block }) => {
  switch (block.type) {
    case "link":
      return (
        <Card className={`preview-block ${isDesktop ? 'preview-block-desktop' : ''}`}>
          <Avatar
            src={block.content.imageUrl}
            shape="square"
            size={64}
            className="block-avatar"
          />
          <a href={block.content.url} target="_blank" className="preview-link" rel="noreferrer">
            {block.content.title}
          </a>
        </Card>
      );
    case "text":
      return (
        <Card className={`preview-block ${isDesktop ? 'preview-block-desktop' : ''} preview-block-text`}>
          <Avatar
            icon={<FileTextOutlined />}
            shape="square"
            size={64}
            className="block-avatar"
          />
          <p className="preview-text">{block.content.content}</p>
        </Card>
      );
    case "contact":
      return (
        <Card className={`preview-block ${isDesktop ? 'preview-block-desktop' : ''}`}>
          <div className="preview-contact">
            {block.content.layout === "center" ? (
              <div className="block-card image-wrapper-center">
                <Avatar size={64} src={<img src={block.content.imageUrl} alt='avatar' />} />
                <div className="content-wrapper-center">
                  <p className="text-center">{block.content.title}</p>
                  <p className="text-center">{block.content.description}</p>
                </div>
              </div>
            ) : (
              <>
                <div className="block-card">
                <Avatar size={64} src={<img src={block.content.imageUrl} alt='avatar' />} />
                  <div className="content-wrapper-left">
                    <p className="text-center">{block.content.title}</p>
                    <p className="text-center">{block.content.description}</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </Card>
      );
    case "event":
      return (
        <Card className={`preview-block ${isDesktop ? 'preview-block-desktop' : ''} event`} onClick={() => window.open(block.content.ticketLink, "_blank")}>
          <span className="event-card-wrapper">
            <p className="event-card-info-secondary">{block.content.eventDate.month}</p>
            <p className="event-card-info-primary">{block.content.eventDate.day}</p>
            <p className="event-card-info-secondary">{block.content.eventDate.year}</p>
          </span>
          <div className="block-content">
            <h4 className="block-title">{block.content.venueName}</h4>
            <p className="block-description">{block.content.location}</p>
            <p className="block-description sold-out">{block.content.isSoldOut ? "Sold Out" : "Tickets"}</p>
          </div>
        </Card>
      );
    default:
      return null;
  }
}

export default TemplateBuilder;
